import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

import {
  content,
  cover,
  center,
  container,
  image,
  larger,
  blue,
  elleft,
  elright,
  grey,
  flex,
  thin,
  sso,
} from '../components/layout.module.css'

const OurTeam = () => {
  return (
    <Layout pageTitle="Our Team">

      <div className={cover}>
        <StaticImage src="../images/team/banner.jpg" alt="" style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            filter: "brightness(0.75)",
          }}
          loading="eager"
          layout="fullWidth"></StaticImage>
        <div className={container +' '+ content}>
          <h1>The Flexible Services Team</h1>
        </div>
      </div>

      <div className={container}>
        <h2>The Flexible Services Team</h2>
        <p className={larger +' '+ thin +' '+ center}>Connect with us to learn more about our experience and for consulting support for co-designing your program to address health-related social needs.</p>
      </div>

      <div className={container}>
        <div className={flex +' '+ center}>
          <div>
            <StaticImage src="../images/team/kim_prendergast.jpg" alt="Kim Prendergast"></StaticImage>
            <p><strong>Kim Prendergast</strong><br/>Vice President,<br/>Policy</p>
          </div>
          <div>
            <StaticImage src="../images/team/annie_pham.png" alt="Annie Pham"></StaticImage>
            <p><strong>Annie Pham</strong><br/>Director,<br/>Social Health</p>
          </div>
            <div>
            <StaticImage src="../images/team/CAdams.jpg" alt="Crystal Adams"></StaticImage>
            <p><strong>Crystal Adams</strong><br/>Manager,<br/>Flexible Services</p>
          </div>
        </div>
        <div className={flex +' '+ center}>
          <div>
            <StaticImage src="../images/team/marina_veiga.jpg" alt="Marina Veiga"></StaticImage>
            <p><strong>Marina Veiga</strong><br/>Senior Program Coordinator,<br/>Flexible Services</p>
          </div>
          <div>
            <StaticImage src="../images/team/grace_akor.jpg" alt="Grace Akor"></StaticImage>
            <p><strong>Grace Akor</strong><br/>Program Coordinator,<br/>Flexible Services</p>
          </div>
          <div>
            <StaticImage src="../images/team/IMG_0416.jpeg" alt="Tatiana Torres"></StaticImage>
            <p><strong>Tatiana Torres</strong><br/>Program Coordinator,<br/>Housing</p>
          </div>
        </div>
      </div>
  
      <div className={blue} style={{position: "relative"}}>
        <StaticImage src="../images/team/quote.jpg" alt="" className={elleft+' '+image}></StaticImage>
        <div className={container}>
          <div className={elright +' '+ larger}>
            <p>For consulting support or questions about our program, email Annie Pham at <a href="mailto:apham@c3aco.org">apham@c3aco.org</a> to learn more.</p>
          </div>
        </div>
      </div>

      <div className={container}>
        <h2>in partnership with Health centers</h2><br/>
        <div className={flex}>
          <a href="https://www.bnhc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/BNHC_Logo.jpg" alt="Brockton Neighborhood Health Center logo" objectFit="contain"></StaticImage></a>
          <a href="https://chcofcapecod.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Community-Health-Center_CAPE.png" alt="Community Health Center of Cape Cod logo" objectFit="contain"></StaticImage></a>
          <a href="https://chcfhc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/community_health_connections.png" alt="
    Community Health Connections logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.charlesriverhealth.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Charles-River-Community-Health.png" alt="Charles River Community Health logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.chcfc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Community-Health-Center-of-Franklin-County.png" alt="Community Health Center of Franklin County logo" objectFit="contain"></StaticImage></a>
        </div><br/><div className={flex}>
          <a href="https://dimock.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Dimock-Center.png" alt="Dimock Center logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.ebnhc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/NeighborhdHealthGroupLogo.png" alt="Neighborhood Health Group logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.kennedychc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Edward-Kennedy-Community-Health-Center.png" alt="Edward Kennedy Community Health Center logo" objectFit="contain"></StaticImage></a>
          <a href="http://www.fhcw.org" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/FHCW-Logo-Circle.png" alt="Family Health Center of Worcester logo" objectFit="contain"></StaticImage></a>
          <a href="https://fenwayhealth.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Fenway-Health.png" alt="Fenway Health logo" objectFit="contain"></StaticImage></a>
        </div><br/><div className={flex}>
          <a href="https://www.hchcweb.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Hilltown-Community-Health-Center.png" alt="Hilltown Community Health Center logo" objectFit="contain"></StaticImage></a>
          <a href="http://www.hhcinc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/HolyokeHealth_4c.png" alt="Holyoke Health Center logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.ihimv.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Island-Health-Care.png" alt="Island -Health Care logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.kronoshealth.com/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Kronos-Health-logo.png" alt="kronos health logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.lchcnet.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Lynn_community.png" alt="Lynn community health center logo" objectFit="contain"></StaticImage></a>
        </div><br/><div className={flex}>
          <a href="https://newhealthcenter.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/NEW-Health-North-End-Waterfront-Health.png" alt="NEW Health North End Waterfront Health center logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.nschi.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/North-Shore-Community-Health.png" alt="North Shore Community Health logo" objectFit="contain"></StaticImage></a>
          <a href="https://www.communitycarecooperative.org/springfield-health-services-for-the-homeless" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/HSH-Logo-SMall.png" alt="springfield health services for the homeless" objectFit="contain" style={{maxWidth:"300px"}}></StaticImage></a>
          <a href="https://uphamscornerhealthcenter.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/healthcenters/Uphams-Corner-Health-Center.png" alt="Uphams Corner Health Center logo" objectFit="contain"></StaticImage></a>
        </div>
      </div>

      <div className={grey}>
        <div className={container}>
          <h2>in partnership with Social Service Organizations</h2><br/>
          <div className={flex}>
            <a href="https://www.bhninc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/BHN-logo.png" alt="Behavioral Health Network, Inc. logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.wayfinders.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/wayfinders.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.communityaction.us/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/capv-logo.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.servings.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/Community-Servings.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.eliotchs.org" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/eliot.png" alt="logo" objectFit="contain"></StaticImage></a>
          </div><br/><div className={flex}>
            <a href="https://eswa.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/ESWA.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://helpfbms.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/fatherbills.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.foodbankwma.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/WMFP.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://haconcapecod.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/HacLogo.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://mahomeless.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/mch.png" alt="logo" objectFit="contain"></StaticImage></a>
          </div><br/><div className={flex}>
            <a href="https://www.mocinc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/MOC_Logo.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.metrohousingboston.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/metrohousingboston.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.prohope.org" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/PH-logo.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.projectbread.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/projectbread.png" alt="logo" objectFit="contain"></StaticImage></a>
            <a href="https://www.smoc.org/" target="_blank" rel="noreferrer noopener" className={sso}><StaticImage src="../images/sso/smoc-logo.svg" alt="logo" objectFit="contain"></StaticImage></a>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default OurTeam
